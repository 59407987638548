import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import Hero from 'components/global/hero'
import StickySidebar from 'components/sidebars/sticky-sidebar'
import ResourcesForm from 'components/forms/resources-form'
import GetInTouch from 'components/get-in-touch/get-in-touch'

const Template = ({ data }) => {
  const d = data.allEventsYaml.edges[0].node

  return (
    <Layout>
      <SEO
        title={d.title}
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description}
        image={d.meta.image} />
      <Hero image={d.image} className="Hero--article" alt={`Background image for ${d.title}`}>
        <div className="container-fluid">
          <h1 className="mb-0 text-light">{d.title}</h1>
          {d.subtitle.split('\n').map((item, i) =>
            <h2 className="text-light font-weight-normal" key={i} >
              {item}
            </h2>
          )}
        </div>
      </Hero>
      <div className="k-bg--sidebar">
        <main className="container-fluid">
          <div className="row">
            <div className="col k-bg--white">
              <section className="k-section">
                <div
                  className="k-markdown-content"
                  dangerouslySetInnerHTML={{ __html: d.content }} />
              </section>
            </div>
            <aside className="col-lg-auto py-5 k-anchor__section k-bg--grey">
              <div id="k-anchor__resource-form" />
              <StickySidebar>
                <h4 className="text-uppercase k-text--heading-linethrough">
                  <span className="k-bg--grey">Sign up to join</span>
                </h4>
                <ResourcesForm resource={d.resource} />
              </StickySidebar>
            </aside>
          </div>
        </main>
      </div>
      <GetInTouch />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    allEventsYaml(filter: { path: { eq: $path } }) {
      edges {
        node {
          path
          meta {
            title
            keywords
            image
            description
          }
          resource {
            name
            subName
            gtmFormId
          }
          title
          subtitle
          image
          content
        }
      }
    }
  }
`

export default Template
