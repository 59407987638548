import React from 'react'
import Image from './../image/image'
import './hero.sass'

const Hero = ({ children, image, alt, className, is_notebook }) => (
  <header className={className}>
    <div className="Hero__filter"/>
    <div className="Hero__background">
      { is_notebook
        ? <img src={`/notebooks/${image}`} alt={alt}/>
        : <Image image_url={image} alt={alt} />
      }
    </div>
    <div className="Hero__body">
      {children}
    </div>
</header>
)

export default Hero
