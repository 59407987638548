import React from 'react'
import HubspotForm from './hubspot-form'
import Loader from 'components/loaders/loader'
import ResponseHandler from './response-handler'
import Input from './input'
import Checkbox from './checkbox'
import MarketingContentText from './marketing-content-text'
import MarketingTermsText from './marketing-terms-text'
import { setFieldsFromQuery } from './form-helpers'

const initialState = {
  fields: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    contentForMarketing: false,
  },
  status: 0,
  response: null,
  loading: false,
}

class Form extends HubspotForm {

  constructor(props){
    super(props)
    this.props.resource.path ?
      this.formId = '568b5cb5-a200-4ce6-8db1-c8e3dbd14b79'
      : this.formId = '20f94774-3eb4-47da-b145-5931aa4d1645'
    this.contentForMarketingId = 5472884
    this.state = initialState
    if (typeof window !== 'undefined' && window.location.search) {
      this.state.fields = setFieldsFromQuery(this.state.fields)
    }
  }

  successCallback() {
    if(this.props.resource.path)
      window.open(this.props.resource.path)
  }


  onChange = (e) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name;
    this.setState({fields: {
                          ...this.state.fields,
                          [name]: value
                        }});
  }

  formContent(hasPDF) {
    return (
      <>
        <h2 className="text-center">{this.props.resource.name}</h2>
        {!this.props.resource.subName ?
          <p className="mb-4">To download the full white paper please enter your details below:</p>
          : <p className="mb-4 text-center">{this.props.resource.subName}</p>}
        <Input
          className="fs-mask-without-consent"
          name="firstname"
          value={this.state.fields.firstname}
          required
          handleChange={this.onChange}>
          First name
        </Input>
        <Input
          className="fs-mask-without-consent"
          name="lastname"
          value={this.state.fields.lastname}
          required
          handleChange={this.onChange}>
          Last name
        </Input>
        <Input
          className="fs-mask-without-consent"
          name="email"
          type="email"
          required
          value={this.state.fields.email}
          handleChange={this.onChange}>
          Company Email
        </Input>
        <Input
          className="fs-mask-without-consent"
          name="phone"
          type="tel"
          value={this.state.fields.phone}
          handleChange={this.onChange}>
          Phone number
        </Input>
        <Checkbox
          name="contentForMarketing"
          type="checkbox"
          value={this.state.fields.contentForMarketing}
          handleChange={this.onChange}>
          <MarketingContentText />
        </Checkbox>
        <MarketingTermsText />
        <button
          disabled={this.state.loading}
          type="submit"
          className="btn btn-primary btn-block text-uppercase d-flex align-items-center justify-content-center">
          { hasPDF && this.state.loading ?
            <Loader />
            : hasPDF && !this.state.loading ?
            'Download'
            : 'Submit'
          }
        </button>
        {this.state.response &&
          <ResponseHandler
            status={this.state.status}
            response={this.state.response} />
        }
      </>
    )
  }

  formResponse(hasPDF) {
    if (hasPDF) {
      return (
        <div className="text-center">
          <h3 className="h2">Thank you. Your document should open shortly&hellip;</h3>
          <p className="mt-3 mb-0">
            If your download does not begin automatically you can <a
              href={this.props.resource.path}
              target="_blank"
              rel="noopener noreferrer">
              click here
            </a> to download manually.
            <br />&bull;<br />
            Team Kortical
          </p>
        </div>
      )
    } else {
      return (
        <div className="text-center">
          <h3 className="h2">Thank you. Looking forward to seeing you there&hellip;</h3>
        </div>
      )
    }
  }

  render () {
    return (
      <form
        id={this.props.resource.path ?
          `gtm-resource-download-${this.props.resource.gtmFormId}`
          :`gtm-resource-hackaton-${this.props.resource.gtmFormId}`
        }
        onSubmit={this.onSubmit}
        className="pb-4 border-bottom border-primary k-form--lined">
        {this.props.resource.path && this.state.status !== 200 && this.formContent(true)}
        {this.props.resource.path && this.state.status === 200 && this.formResponse(true)}
        {!this.props.resource.path && this.state.status !== 200 && this.formContent(false)}
        {!this.props.resource.path && this.state.status === 200 && this.formResponse(false)}
      </form>
    )
  }

}

export default Form
