import React from 'react'
import './sticky-sidebar.sass'


const StickySidebar = (props) => (
  <div className="k-sidebar k-sidebar__sticky">
      <div className="sidebar__inner">
          {props.children}
      </div>
  </div>
)

export default StickySidebar
